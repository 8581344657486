import { default as aboutvf5lf9hGCDMeta } from "/opt/render/project/src/pages/about.vue?macro=true";
import { default as indexBFBjK3vQXZMeta } from "/opt/render/project/src/pages/account/index.vue?macro=true";
import { default as resetPasswordt4LQZlM6UVMeta } from "/opt/render/project/src/pages/account/resetPassword.vue?macro=true";
import { default as signinglJ93fvV9sMeta } from "/opt/render/project/src/pages/account/signin.vue?macro=true";
import { default as signuph3egUA2tjaMeta } from "/opt/render/project/src/pages/account/signup.vue?macro=true";
import { default as achievementsuxoOjlvzgpMeta } from "/opt/render/project/src/pages/achievements.vue?macro=true";
import { default as archivexQjToXCeO1Meta } from "/opt/render/project/src/pages/archive.vue?macro=true";
import { default as _91slug_93xMgGYSZVcyMeta } from "/opt/render/project/src/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93W6gPRBNpVVMeta } from "/opt/render/project/src/pages/categories/[slug].vue?macro=true";
import { default as _91id_93aOcmSg5XQeMeta } from "/opt/render/project/src/pages/colaborate/[id].vue?macro=true";
import { default as indexEaxGpappd4Meta } from "/opt/render/project/src/pages/colaborate/index.vue?macro=true";
import { default as contact15m9h4kbk7Meta } from "/opt/render/project/src/pages/contact.vue?macro=true";
import { default as cookiesPolicyCtuz7eXHRjMeta } from "/opt/render/project/src/pages/cookiesPolicy.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as infographicQQ1vnbpkfVMeta } from "/opt/render/project/src/pages/infographic.vue?macro=true";
import { default as legalAdvice7Oamcw28ORMeta } from "/opt/render/project/src/pages/legalAdvice.vue?macro=true";
import { default as indexzNB5AbdtxbMeta } from "/opt/render/project/src/pages/podcast/index.vue?macro=true";
import { default as privacyPolicykliUaqKE9OMeta } from "/opt/render/project/src/pages/privacyPolicy.vue?macro=true";
import { default as search06tVE08D9HMeta } from "/opt/render/project/src/pages/search.vue?macro=true";
import { default as webinarHZtjSGc7fkMeta } from "/opt/render/project/src/pages/webinar.vue?macro=true";
export default [
  {
    name: aboutvf5lf9hGCDMeta?.name ?? "about___es",
    path: aboutvf5lf9hGCDMeta?.path ?? "/cosas-militares",
    meta: aboutvf5lf9hGCDMeta || {},
    alias: aboutvf5lf9hGCDMeta?.alias || [],
    redirect: aboutvf5lf9hGCDMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutvf5lf9hGCDMeta?.name ?? "about___en",
    path: aboutvf5lf9hGCDMeta?.path ?? "/en/about",
    meta: aboutvf5lf9hGCDMeta || {},
    alias: aboutvf5lf9hGCDMeta?.alias || [],
    redirect: aboutvf5lf9hGCDMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexBFBjK3vQXZMeta?.name ?? "account___es",
    path: indexBFBjK3vQXZMeta?.path ?? "/mi-cuenta",
    meta: indexBFBjK3vQXZMeta || {},
    alias: indexBFBjK3vQXZMeta?.alias || [],
    redirect: indexBFBjK3vQXZMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexBFBjK3vQXZMeta?.name ?? "account___en",
    path: indexBFBjK3vQXZMeta?.path ?? "/en/account",
    meta: indexBFBjK3vQXZMeta || {},
    alias: indexBFBjK3vQXZMeta?.alias || [],
    redirect: indexBFBjK3vQXZMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordt4LQZlM6UVMeta?.name ?? "account-resetPassword___es",
    path: resetPasswordt4LQZlM6UVMeta?.path ?? "/cambiar-contrasena",
    meta: resetPasswordt4LQZlM6UVMeta || {},
    alias: resetPasswordt4LQZlM6UVMeta?.alias || [],
    redirect: resetPasswordt4LQZlM6UVMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordt4LQZlM6UVMeta?.name ?? "account-resetPassword___en",
    path: resetPasswordt4LQZlM6UVMeta?.path ?? "/en/reset-password",
    meta: resetPasswordt4LQZlM6UVMeta || {},
    alias: resetPasswordt4LQZlM6UVMeta?.alias || [],
    redirect: resetPasswordt4LQZlM6UVMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: signinglJ93fvV9sMeta?.name ?? "account-signin___es",
    path: signinglJ93fvV9sMeta?.path ?? "/acceder",
    meta: signinglJ93fvV9sMeta || {},
    alias: signinglJ93fvV9sMeta?.alias || [],
    redirect: signinglJ93fvV9sMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/signin.vue").then(m => m.default || m)
  },
  {
    name: signinglJ93fvV9sMeta?.name ?? "account-signin___en",
    path: signinglJ93fvV9sMeta?.path ?? "/en/signin",
    meta: signinglJ93fvV9sMeta || {},
    alias: signinglJ93fvV9sMeta?.alias || [],
    redirect: signinglJ93fvV9sMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/signin.vue").then(m => m.default || m)
  },
  {
    name: signuph3egUA2tjaMeta?.name ?? "account-signup___es",
    path: signuph3egUA2tjaMeta?.path ?? "/suscripcion",
    meta: signuph3egUA2tjaMeta || {},
    alias: signuph3egUA2tjaMeta?.alias || [],
    redirect: signuph3egUA2tjaMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/signup.vue").then(m => m.default || m)
  },
  {
    name: signuph3egUA2tjaMeta?.name ?? "account-signup___en",
    path: signuph3egUA2tjaMeta?.path ?? "/en/membership",
    meta: signuph3egUA2tjaMeta || {},
    alias: signuph3egUA2tjaMeta?.alias || [],
    redirect: signuph3egUA2tjaMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/account/signup.vue").then(m => m.default || m)
  },
  {
    name: achievementsuxoOjlvzgpMeta?.name ?? "achievements___es",
    path: achievementsuxoOjlvzgpMeta?.path ?? "/achievements",
    meta: achievementsuxoOjlvzgpMeta || {},
    alias: achievementsuxoOjlvzgpMeta?.alias || [],
    redirect: achievementsuxoOjlvzgpMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/achievements.vue").then(m => m.default || m)
  },
  {
    name: achievementsuxoOjlvzgpMeta?.name ?? "achievements___en",
    path: achievementsuxoOjlvzgpMeta?.path ?? "/en/achievements",
    meta: achievementsuxoOjlvzgpMeta || {},
    alias: achievementsuxoOjlvzgpMeta?.alias || [],
    redirect: achievementsuxoOjlvzgpMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/achievements.vue").then(m => m.default || m)
  },
  {
    name: archivexQjToXCeO1Meta?.name ?? "archive___es",
    path: archivexQjToXCeO1Meta?.path ?? "/archive",
    meta: archivexQjToXCeO1Meta || {},
    alias: archivexQjToXCeO1Meta?.alias || [],
    redirect: archivexQjToXCeO1Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: archivexQjToXCeO1Meta?.name ?? "archive___en",
    path: archivexQjToXCeO1Meta?.path ?? "/en/archive",
    meta: archivexQjToXCeO1Meta || {},
    alias: archivexQjToXCeO1Meta?.alias || [],
    redirect: archivexQjToXCeO1Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xMgGYSZVcyMeta?.name ?? "blog-slug___es",
    path: _91slug_93xMgGYSZVcyMeta?.path ?? "/blog/:slug",
    meta: _91slug_93xMgGYSZVcyMeta || {},
    alias: _91slug_93xMgGYSZVcyMeta?.alias || [],
    redirect: _91slug_93xMgGYSZVcyMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xMgGYSZVcyMeta?.name ?? "blog-slug___en",
    path: _91slug_93xMgGYSZVcyMeta?.path ?? "/en/blog/:slug",
    meta: _91slug_93xMgGYSZVcyMeta || {},
    alias: _91slug_93xMgGYSZVcyMeta?.alias || [],
    redirect: _91slug_93xMgGYSZVcyMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W6gPRBNpVVMeta?.name ?? "categories-slug___es",
    path: _91slug_93W6gPRBNpVVMeta?.path ?? "/categories/:slug()",
    meta: _91slug_93W6gPRBNpVVMeta || {},
    alias: _91slug_93W6gPRBNpVVMeta?.alias || [],
    redirect: _91slug_93W6gPRBNpVVMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W6gPRBNpVVMeta?.name ?? "categories-slug___en",
    path: _91slug_93W6gPRBNpVVMeta?.path ?? "/en/categories/:slug()",
    meta: _91slug_93W6gPRBNpVVMeta || {},
    alias: _91slug_93W6gPRBNpVVMeta?.alias || [],
    redirect: _91slug_93W6gPRBNpVVMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93aOcmSg5XQeMeta?.name ?? "colaborate-id___es",
    path: _91id_93aOcmSg5XQeMeta?.path ?? "/colaborador/:id",
    meta: _91id_93aOcmSg5XQeMeta || {},
    alias: _91id_93aOcmSg5XQeMeta?.alias || [],
    redirect: _91id_93aOcmSg5XQeMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/colaborate/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93aOcmSg5XQeMeta?.name ?? "colaborate-id___en",
    path: _91id_93aOcmSg5XQeMeta?.path ?? "/en/colaborator/:id",
    meta: _91id_93aOcmSg5XQeMeta || {},
    alias: _91id_93aOcmSg5XQeMeta?.alias || [],
    redirect: _91id_93aOcmSg5XQeMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/colaborate/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEaxGpappd4Meta?.name ?? "colaborate___es",
    path: indexEaxGpappd4Meta?.path ?? "/colabora",
    meta: indexEaxGpappd4Meta || {},
    alias: indexEaxGpappd4Meta?.alias || [],
    redirect: indexEaxGpappd4Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/colaborate/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaxGpappd4Meta?.name ?? "colaborate___en",
    path: indexEaxGpappd4Meta?.path ?? "/en/colaborate",
    meta: indexEaxGpappd4Meta || {},
    alias: indexEaxGpappd4Meta?.alias || [],
    redirect: indexEaxGpappd4Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/colaborate/index.vue").then(m => m.default || m)
  },
  {
    name: contact15m9h4kbk7Meta?.name ?? "contact___es",
    path: contact15m9h4kbk7Meta?.path ?? "/contacto",
    meta: contact15m9h4kbk7Meta || {},
    alias: contact15m9h4kbk7Meta?.alias || [],
    redirect: contact15m9h4kbk7Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact15m9h4kbk7Meta?.name ?? "contact___en",
    path: contact15m9h4kbk7Meta?.path ?? "/en/contact",
    meta: contact15m9h4kbk7Meta || {},
    alias: contact15m9h4kbk7Meta?.alias || [],
    redirect: contact15m9h4kbk7Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookiesPolicyCtuz7eXHRjMeta?.name ?? "cookiesPolicy___es",
    path: cookiesPolicyCtuz7eXHRjMeta?.path ?? "/politica-de-cookies",
    meta: cookiesPolicyCtuz7eXHRjMeta || {},
    alias: cookiesPolicyCtuz7eXHRjMeta?.alias || [],
    redirect: cookiesPolicyCtuz7eXHRjMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/cookiesPolicy.vue").then(m => m.default || m)
  },
  {
    name: cookiesPolicyCtuz7eXHRjMeta?.name ?? "cookiesPolicy___en",
    path: cookiesPolicyCtuz7eXHRjMeta?.path ?? "/en/cookies-policy",
    meta: cookiesPolicyCtuz7eXHRjMeta || {},
    alias: cookiesPolicyCtuz7eXHRjMeta?.alias || [],
    redirect: cookiesPolicyCtuz7eXHRjMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/cookiesPolicy.vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index___es",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index___en",
    path: indexYy8SidDWaAMeta?.path ?? "/en",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infographicQQ1vnbpkfVMeta?.name ?? "infographic___es",
    path: infographicQQ1vnbpkfVMeta?.path ?? "/infografias",
    meta: infographicQQ1vnbpkfVMeta || {},
    alias: infographicQQ1vnbpkfVMeta?.alias || [],
    redirect: infographicQQ1vnbpkfVMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/infographic.vue").then(m => m.default || m)
  },
  {
    name: infographicQQ1vnbpkfVMeta?.name ?? "infographic___en",
    path: infographicQQ1vnbpkfVMeta?.path ?? "/en/infographic",
    meta: infographicQQ1vnbpkfVMeta || {},
    alias: infographicQQ1vnbpkfVMeta?.alias || [],
    redirect: infographicQQ1vnbpkfVMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/infographic.vue").then(m => m.default || m)
  },
  {
    name: legalAdvice7Oamcw28ORMeta?.name ?? "legalAdvice___es",
    path: legalAdvice7Oamcw28ORMeta?.path ?? "/aviso-legal",
    meta: legalAdvice7Oamcw28ORMeta || {},
    alias: legalAdvice7Oamcw28ORMeta?.alias || [],
    redirect: legalAdvice7Oamcw28ORMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/legalAdvice.vue").then(m => m.default || m)
  },
  {
    name: legalAdvice7Oamcw28ORMeta?.name ?? "legalAdvice___en",
    path: legalAdvice7Oamcw28ORMeta?.path ?? "/en/legal-advice",
    meta: legalAdvice7Oamcw28ORMeta || {},
    alias: legalAdvice7Oamcw28ORMeta?.alias || [],
    redirect: legalAdvice7Oamcw28ORMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/legalAdvice.vue").then(m => m.default || m)
  },
  {
    name: indexzNB5AbdtxbMeta?.name ?? "podcast___es",
    path: indexzNB5AbdtxbMeta?.path ?? "/podcast",
    meta: indexzNB5AbdtxbMeta || {},
    alias: indexzNB5AbdtxbMeta?.alias || [],
    redirect: indexzNB5AbdtxbMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/podcast/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNB5AbdtxbMeta?.name ?? "podcast___en",
    path: indexzNB5AbdtxbMeta?.path ?? "/en/podcast",
    meta: indexzNB5AbdtxbMeta || {},
    alias: indexzNB5AbdtxbMeta?.alias || [],
    redirect: indexzNB5AbdtxbMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/podcast/index.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicykliUaqKE9OMeta?.name ?? "privacyPolicy___es",
    path: privacyPolicykliUaqKE9OMeta?.path ?? "/politica-de-privacidad",
    meta: privacyPolicykliUaqKE9OMeta || {},
    alias: privacyPolicykliUaqKE9OMeta?.alias || [],
    redirect: privacyPolicykliUaqKE9OMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicykliUaqKE9OMeta?.name ?? "privacyPolicy___en",
    path: privacyPolicykliUaqKE9OMeta?.path ?? "/en/privacy-policy",
    meta: privacyPolicykliUaqKE9OMeta || {},
    alias: privacyPolicykliUaqKE9OMeta?.alias || [],
    redirect: privacyPolicykliUaqKE9OMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: search06tVE08D9HMeta?.name ?? "search___es",
    path: search06tVE08D9HMeta?.path ?? "/buscador",
    meta: search06tVE08D9HMeta || {},
    alias: search06tVE08D9HMeta?.alias || [],
    redirect: search06tVE08D9HMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search06tVE08D9HMeta?.name ?? "search___en",
    path: search06tVE08D9HMeta?.path ?? "/en/search",
    meta: search06tVE08D9HMeta || {},
    alias: search06tVE08D9HMeta?.alias || [],
    redirect: search06tVE08D9HMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: webinarHZtjSGc7fkMeta?.name ?? "webinar___es",
    path: webinarHZtjSGc7fkMeta?.path ?? "/webinar",
    meta: webinarHZtjSGc7fkMeta || {},
    alias: webinarHZtjSGc7fkMeta?.alias || [],
    redirect: webinarHZtjSGc7fkMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/webinar.vue").then(m => m.default || m)
  },
  {
    name: webinarHZtjSGc7fkMeta?.name ?? "webinar___en",
    path: webinarHZtjSGc7fkMeta?.path ?? "/en/webinar",
    meta: webinarHZtjSGc7fkMeta || {},
    alias: webinarHZtjSGc7fkMeta?.alias || [],
    redirect: webinarHZtjSGc7fkMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/webinar.vue").then(m => m.default || m)
  }
]