export const localeCodes = ["es","en"]

export const localeMessages = {
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "lang_es_json_es_json" */) }],
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "lang_en_json_en_json" */) }],
}

export const additionalMessages = Object({"es":[],"en":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.compilation = Object({"strictMessage":true,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  nuxtI18nOptions.vueI18n = Object({})
  nuxtI18nOptions.locales = [Object({"code":"es","iso":"es-ES","file":"es.json","name":"Español","hash":"d321739b","type":"static"}),Object({"code":"en","iso":"en-US","file":"en.json","name":"English","hash":"1b6ca73e","type":"static"})]
  nuxtI18nOptions.defaultLocale = "es"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = "https://thepoliticalroom.com"
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "config"
  nuxtI18nOptions.pages = Object({"about":Object({"es":"/cosas-militares","en":"/about"}),"contact":Object({"es":"/contacto","en":"/contact"}),"search":Object({"es":"/buscador","en":"/search"}),"colaborate/index":Object({"es":"/colabora","en":"/colaborate"}),"colaborate/[id]":Object({"es":"/colaborador/[id]","en":"/colaborator/[id]"}),"privacyPolicy":Object({"es":"/politica-de-privacidad","en":"/privacy-policy"}),"cookiesPolicy":Object({"es":"/politica-de-cookies","en":"/cookies-policy"}),"legalAdvice":Object({"es":"/aviso-legal","en":"/legal-advice"}),"infographic":Object({"es":"/infografias","en":"/infographic"}),"webinar":Object({"es":"/webinar","en":"/webinar"}),"blog/[slug]":Object({"es":"/blog/[slug]","en":"/blog/[slug]"}),"account/index":Object({"es":"/mi-cuenta","en":"/account"}),"account/signin":Object({"es":"/acceder","en":"/signin"}),"account/signup":Object({"es":"/suscripcion","en":"/membership"}),"account/resetPassword":Object({"es":"/cambiar-contrasena","en":"/reset-password"})})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),compilation: Object({"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"es","iso":"es-ES","file":"es.json","name":"Español","hash":"d321739b","type":"static"}),Object({"code":"en","iso":"en-US","file":"en.json","name":"English","hash":"1b6ca73e","type":"static"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
